import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly toastr: ToastrService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response) => {
        switch (response.status) {
          case 400:
            return this.Handle400(response);
          case 401:
            return this.Handle401(request, response);
          case 403:
            return this.Handle403(response);
          case 500:
            return this.Handle500(response);
          case 308:
            return this.Handle308(response);
          default:
            return this.Handle500(response);
        }
      })
    );
  }

  private async Handle400(response: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const errors: string[] = [];

      if (
        response instanceof HttpErrorResponse &&
        response.error instanceof Blob &&
        response.error.type === 'application/problem+json'
      ) {
        try {
          const errorResponse: any = await new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (e: Event) => {
              resolve(JSON.parse((<any>e.target).result));
            };
            reader.onerror = reject;
            reader.readAsText(response.error);
          });

          for (let parentKey in errorResponse.errors) {
            for (let childKey in errorResponse.errors[parentKey]) {
              errors.push(errorResponse.errors[parentKey][childKey]);
            }
          }
          errors.push(errorResponse.detail)
        } catch (e) { }
      }

      if (errors.length) {
        for (let error of errors) {
          console.warn(error);

          this.toastr.error(error);
        }
      } else {
        this.toastr.error('Ocorreu um erro na sua requisição', 'Ops!');
      }

      reject(response);
    });
  }

  private async Handle401(request: HttpRequest<any>, response: any): Promise<any> {
    // Se a URL da requisição contém '/api/validateToken', não redirecionamos
    if (request.url.includes('/validate')) {
      return Promise.reject(response);
    }

    this.router.navigate(['authentication/login'], { queryParams: { returnUrl: this.router.url } });
    return new Promise(async (resolve, reject) => {
      this.toastr.error('Você precisa acessar o sistema com seu login e senha para executar essa operação.', 'Ops');
      reject(response);
    });
  }

  private async Handle403(response: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.toastr.error('Você não possui permissão para executar essa operação.', 'Ops!');

      reject(response);
    });
  }

  private async Handle500(response: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.toastr.error('Ocorreu um erro inesperado.', 'Ops!');
      reject(response);
    });
  }


  private async Handle308(response: any): Promise<any> {
    if (response instanceof HttpErrorResponse && response.error instanceof Blob) {
        const errorResponse: any = await new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = (e: Event) => {
            resolve(JSON.parse((<any>e.target).result));
          };
          reader.onerror = reject;
          reader.readAsText(response.error);
        });

        const url = errorResponse.detail;

        if(url === 'terms') {
          this.router.navigate([`/${url}`]);
          this.toastr.error('Você precisa aceitar os termos para continuar.', 'Ops!');
        }
        
        return Promise.reject(response);
    }
      
  }
}
